@import url("https://fonts.googleapis.com/css2?family=Mitr&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kodchasan:ital,wght@0,400;0,600;1,300&display=swap");
.nav {
  background: rgb(243, 155, 24);
  height: 70px;
  padding: 15px 10px 10px 10px;
  box-shadow: 4px 4px 6px rgb(192, 188, 188);
}

table,
th,
td {
  border: 1px solid black;
}
.nav .title-nav {
  font-size: 24px;
}

.box {
  margin-top: 3rem;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 10%;
}

.label {
  font-family: "Mitr", sans-serif;
}

.title-nav {
  font-size: 20px;
  text-align: center;
  font-family: "Mitr", sans-serif;
  padding: 0px 0px 10px 0px;
  color: "black";
}

.button1 {
  background-color: rgb(243, 155, 24);
  margin-top: 15px;
  width: 100%;
  font-family: "Mitr", sans-serif;
  border: none;
}

.button1:hover {
  background-color: rgb(228, 157, 52);
}

.result {
  font-family: "Mitr", sans-serif;
  font-size: 18px;
  text-align: center;
  margin-top: 3rem;
  padding: 30px 30px 30px 30px;
  /* background-color: rgb(213, 215, 218); */
  background-color: white;
  border-radius: 4px;
  color: black;
}

.decoration {
  height: 100px;
  width: 1px;
  background-color: #dddbdb;
  display: block;
}

.footer {
  background-color: rgb(50, 50, 50);
  margin-top: 89px;
  color: rgb(255, 255, 255);
  padding: 15px;
}

.where {
  font-family: "Kodchasan", sans-serif;
  background-color: rgb(230, 230, 230);
  padding: 10px 10px;
  border-radius: 14px;
  color: black;
}

@media screen and (max-width: 768px) {
  .title-nav {
    text-align: center;
    font-family: "Mitr", sans-serif;
    padding: 0px 0px 10px 0px;
  }
  .logo img {
    width: 50%;
  }
  .button1 {
    margin-top: 0px;
  }
  .decoration {
    height: 1px;
    width: 100%;
    background-color: #dddbdb;
    display: block;
    /* display: none; */
  }
}
